.feedback {
	padding-bottom: 100px;

	h1 {
		font-family: 'open_sanssemibold';
		font-size: 24px;
		margin-bottom: 20px;
	}

	.feedbackItem {
		border: 1px solid #e4e4e4;
		margin-bottom: 16px;
		padding: 30px;

		.feedbackItem-title {
			float: left;
		}

		.feedbackItem-orderNumber {
			float: right;
		}

		.feedbackItem-message {
			padding: 0 20px;
		}
	}
}
