.account-settings {
	
	form {
		margin: 0 auto;
		width: 640px;
	}

	.form-group {
		margin-bottom: 46px;

		strong {
			text-transform: uppercase;
		}

		.form-group-item {
			font-size: 14px;
			margin-bottom: 16px;
		}

		.form-group-item {

			.form-group-label,
			.form-group-input {
				float: left;
			}

			.form-group-label {
				text-align: right;
				margin-top: 12px;
				padding-right: 25px;
				width: 170px;
			}

			.form-group-input {

				input[type="text"],
				input[type="password"] {
					border: 1px solid #cccccc;
					border-radius: 5px;
					padding: 0 20px;
					width: 470px;
					height: 45px;
				}

				input[type="submit"] {
					cursor: pointer;
					background: #ffac00;
					color: #ffffff;
					font-family: 'open_sansbold';
					text-transform: uppercase;
					border: 0;
					border-radius: 5px;
					width: 470px;
					height: 45px;
				}
			}
		}
	}
}
