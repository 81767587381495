/*CSS DOCUMENTS*/
* {
	margin:0;
	padding:0;
}

html, body {
	font-family: 'open_sansregular';
	margin:0;
	padding:0;
	width: 100%;
	min-height: 100% !important;
	height: 100%;
}

input[type="text"].is-invalid,
input[type="password"].is-invalid {
	border: 1px solid #cf4a48 !important;
}

.clearfix {
	float: none;
	clear: both;
}

.error {
	color: #FF0000;
}

.btn-login-primary {
	background: #ffac00;
	color: #ffffff;
}

.btn-primary-blue {
	background: #2795c9;
}

.btn-primary-orange {
	background: #ffac00;
	color: #ffffff;
	font-family: 'open_sanssemibold';
}

.modalCloseButton {
	display: block;
	background: url('../images/icon-close.jpg') no-repeat;
	width: 35px;
	height: 35px;
}

.contentWrapper {
	padding: 38px 0;

	h2 {
		font-size: 20px;
		font-family: 'open_sanssemibold';
	}
}

.content {
	min-height: 100% !important;
	margin-bottom: 89px;
}

.visiblePcBlock {
	display: block;
}

.visibleMobileBlock {
	display: none;
}

@media only screen and (max-width: 768px) {
	.visiblePcBlock {
		display: none;
	}

	.visibleMobileBlock {
		display: block;
	}
}

#main {
	height: 100%;

	header {

		.header-top {
			background: #585858;
			padding: 12px 0;

			.logo {
				float: left;
			}

			.login-status {
				float: right;
				color: #ffffff;
				font-family: 'open_sansbold';

				p,
				a {
					float: left;
				}

				p {
					float: left;
					margin: 11px 24px 0 0;

					span {
						color: #ffba00;
						font-family: 'open_sansregular';
					}
				}

				a {
					background: #4a4a4c;
					color: #ffffff;
					font-family: 'open_sansregular';
					border-radius: 5px;
					padding: 13px 15px;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		.header-bottom  {
			background-color: #f7f7f7;
			font-size: 15px;

			nav {

				ul {
					list-style: none;
					text-align: center;
					margin: 0;

					li {
						display: inline-block;
						width: 20%;
							float: left;

						&.selected {
							border-bottom: 3px solid #ffac00;
						}

						&:last-child {
							margin-right: 0;
						}

						a {
							font-family: 'open_sansbold';
							color: #373737;
							display: inline-block;
							padding: 20px 0px 10px;
					    border-bottom: 3px solid #f7f7f7;

							&.active,
							&:hover {
								text-decoration: none;
								border-bottom: 3px solid #fd7003;
							}
						}
					}
				}
			}
		}
	}

	footer {
		background: #414141;
		color: #FFFFFF;
		font-size: 13px;
		padding: 15px 0;
		margin: -49px 0 0;

		p {
			float: left;
			margin: 0;
		}

		a {
			float: right;
			color: #FFFFFF;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

@media only screen and (max-width: 768px) {
	#main {

		header {

			.header-top {

				.logo {
					position: relative;
					width: 100%;

					.menu-icon-nav {
						position: absolute;
						display: block;
						top: 10px;
						right: 5px;
					}
				}
			}
		}
	}
}

@import "icon.scss";
@import "vendor/fonts.scss";
@import "vendor/bootstrap.min.scss";
@import "vendor/jquery.dm-uploader.min.scss";
@import "vendor/jquery.dataTables.min.scss";
@import "vendor/responsive.dataTables.min.scss";

/* ============
	Components
============ */
@import "components/login/login.scss";
@import "components/current-projects/current-projects.scss";
@import "components/current-projects/current-projects.mobile.scss";
@import "components/project-detail/project-detail.scss";
@import "components/account-settings/account-settings.scss";
@import "components/feedback/feedback.scss";
@import "components/payslip/payslip.scss";
@import "custom.scss";
