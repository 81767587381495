.icon {
	position: relative;
	display: block;
}

.icon-menu-mobile {
	background: url('../images/menu_mobile_bar.png') no-repeat;
	width: 31px;
	height: 26px;
}
