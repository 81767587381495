.payslip {
	.nav-tabs {
		font-family: 'open_sanssemibold';
		border: none;

		> li {
      display: inline-block;

			a {
				color: #2F2F2F;
				border: none;
				border-radius: 0;
				padding: 10px;

				&.active,
				&:focus,
				&:hover {
					background-color: #1a91a1;
					color: #FFFFFF;
					text-decoration: none;
				}
			}
		}
	}

  .nav-tab-content {
    > div {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}
