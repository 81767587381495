.login {
	text-transform: uppercase;
	text-align: center;
	box-shadow: 0 0 20px #e7e7e7;
	margin: 170px auto 0; 
	padding-bottom: 66px;
	width: 470px;

	form {
		padding: 0 50px;

		h1 {
			font-size: 30px;
			margin: 30px 0;

			span {
				font-family: 'open_sansbold';
			}
		}

		.logo {
			color: #3e3c35;

			img {
				margin-top: -63px;
			}
		}

		.form-group {

			input[type="text"],
			input[type="password"] {
				border: 1px solid #cccccc;
				border-radius: 5px;
				padding: 0 20px;
				width: 100%;
				height: 52px;
			}

			button {
				font-family: 'open_sansbold';
				text-transform: uppercase;
				width: 100%;
				height: 49px;
			}

			span {
				display: block;
				font-size: 14px;
				margin: 25px 0 0;

				a {
					color: #ffac00;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
}
