// table.dataTable {
//   &.display {
//     tbody {
//       tr {
//         &.odd {
//           background-color: #fafafa;
//         }
//       }
//     }
//   }
// }