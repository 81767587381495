@media only screen and (max-width: 768px) {
	
	.current-projects {
		
		.current-projects-table {

			#newProjectinvite,
			#currentProject {

				&.dataTable {

					&.hover,
					&.display {

						tbody {

							tr {

								&:hover{
									background-color: #EF8026;
									color: #464646;
								}
							}
						}
					}
				}
			}
		}
	}
}