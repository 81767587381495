.payslip-page,
.current-projects {

  h2 {
    color: #3e3c35;
    margin-left: 6px;
    margin-bottom: 24px;
    float: left;

    &.new-project {
      float: right;
      margin-right: 30px;
      color: #ef8026;
    }

    &.declined-project {
      float: right;
      margin-right: 30px;
      color: #ff5b61;
    }
  }

  .payslip-page-table,
  .current-projects-table {
    color: #222222;
    margin-top: 20px;

    #payslipOnProcessTab,
    #payslipDeductionTab,
    #payslipPaidTab,
    #newProjectinvite,
    #currentProject {
      border-top: 1px solid #d1e1e1;
      border-bottom: 0;

      &.dataTable {

        thead th {
          border-bottom: 1px solid #d1e1e1;
        }

        thead th,
        tbody td {
          &:nth-child(2),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            text-align: center;
          }
        }

        tbody {
          color: #464646;
        }


        &.hover,
        &.display {

          tbody {

            tr {

              &.child {
                color: #464646 !important;
              }

              &.odd {
                background-color: #fafafa;
              }

              &:hover{
                background-color: #EF8026;
                color: #FFFFFF;

                a {

                  &:hover {
                    text-decoration: none;
                  }
                }

                td:nth-child(6) {
                  color: #FFFFFF;
                }
              }

              td {
                border-top: none;
                padding: 15px 18px;
                white-space: initial;

                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6) {
                  text-align: center;
                }

                &:first-child {
                  padding-left: 25px;

                  &:before {
                    top: 18px;
                  }
                }

                &.bold {
                  font-family: 'open_sansbold';
                }

                &.new-project {
                  color: #ef8026;
                }

                &.in-progress {
                  color: #29ba41;
                }

                &.declined {
                  color: #ff5b61;
                }
              }
            }
          }
        }
      }
    }
  }

  .inviteWrapper {
    margin-bottom: 30px;
  }

  .dataTables_wrapper {

    .dataTables_filter {

      input {
        border: 1px solid #d1d1d1;
        border-radius: 5px;
        padding: 5px;
      }
    }

    .dataTables_paginate {
      border: 1px solid #dee2e6;
      border-radius: 5px;
      padding-top: 0;
      margin-top: 25px;

      .paginate_button {

        &.current,
        &.current:hover {
          color: #4f7bff!important;
          border: 0;
          border-right: 1px solid #dee2e6;
          background: none;
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:active {
          color: #4f7bff!important;
        }

        &.disabled,
        &.disabled:hover,
        &.disabled:active {
          border-right: 1px solid #dee2e6;
        }
      }
    }
  }
}
