.projectDetail {

  .projectDetail-status {
    float: right;
    font-family: 'open_sanssemibold';
    font-size: 18px;
    text-transform: uppercase;

    p {
      float: left;
      margin-left: 18px;

      span {
        color: #fd7003;
      }
    }
  }

  .projectDetail-information {

    .projectDetail-information-grouped {
      list-style: none;
      border: 1px solid #e4e4e4;
      border-bottom: 0;
      padding: 0;

      .projectDetail-information-item {
        float: none;
        clear: both;
        border-bottom: 1px solid #e4e4e4;

        .projectDetail-information-label,
        .projectDetail-information-description {
          float: left;
        }

        .projectDetail-information-label {
          background: #f7f7f7;
          text-transform: uppercase;
          font-family: 'open_sansbold';
          padding: 20px;
          width: 292px;
        }

        .projectDetail-information-description {
          padding: 20px;
        }
      }
    }
  }

  .projectDetail-download {
    background: #f7f7f7;
    margin-bottom: 12px;
    padding: 10px 15px;

    p {
      float: left;
      font-family: 'open_sansbold';
      text-transform: uppercase;
      margin: 0;
      margin-top: 10px;
    }

    a {
      float: right;
      background: #ef8026;
      color: #ffffff;
      font-family: 'open_sanssemibold';
      text-transform: uppercase;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .projectDetail-clientInstructions {
    margin-bottom: 20px;
  }

  .projectDetail-adminInstructions {
    background: #fff7e6;
  }

  .projectDetail-action-buttons {
    margin-top: 38px;

    a {
      float: left;
      color: #343333;
      font-family: 'open_sanssemibold';
      font-size: 16px;
      text-transform: uppercase;

      &:hover {
        text-decoration: none;
      }
    }

    .projectDetail-buttons {
      float: right;
      font-size: 16px;

      button {
        color: #ffffff;
        font-family: 'open_sanssemibold';
        text-transform: uppercase;
        margin-left: 19px;
      }
    }
  }

  .projectDetail-clientInstructions,
  .projectDetail-adminInstructions {
    border: 1px solid #e4e4e4;
    padding: 20px;

    a {
      display: block;
      color: #3e3c35;
      font-family: 'open_sansbold';
      text-transform: uppercase;
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }

    > a {
      &:after {
        font-family: 'FontAwesome';
        content: "\f107";
        float: right;
        font-size: 26px;
        line-height: 1;
      }

      &[aria-expanded="true"]:after {
        content: "\f106";
      }
    }

    span {
      float: right;
      display: block;
      background: url('../images/arrow_down.png') no-repeat;
      width: 20px;
      height: 20px;
    }
  }

  .projectDetail-revisions {
    margin-top: 20px;

    .projectDetail-revisions-collapse {
      display: block;
      background: #2795c9;
      color: #ffffff;
      font-family: 'open_sansbold';
      text-transform: uppercase;
      padding: 20px;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    > a {
      &:after {
        font-family: 'FontAwesome';
        content: "\f107";
        float: right;
        font-size: 26px;
        line-height: 1;
      }

      &[aria-expanded="true"]:after {
        content: "\f106";
      }
    }

    ul {
      list-style: none;

      li {
        border-bottom: 1px solid #e6e6e6;
        padding: 25px 0;
        &:after {
          content: "";
          display: block;
          clear: both;
        }

        p {
          float: left;
          padding-top: 10px;
          margin-bottom: 0;
        }

        a {
          float: right;
          display: block;
          background: #fd7003;
          color: #ffffff;
          font-family: 'open_sanssemibold';
          text-transform: uppercase;
        }
      }
    }
  }
}

#dropProject {

  .modal-content {
    border-radius: 0;

    .modal-header {
      position: relative;
      font-family: 'open_sansbold';
      font-size: 24px;
      border: 0;

      .modal-title {
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
      }

      .close {
        position: absolute;
        opacity: 1;
        top: -15px;
        right: -15px;
      }
    }
  }

  .modal-body {
    text-align: center;

    span {
      display: block;
      font-size: 14px;
      margin-bottom: 28px;
    }

    p {
      font-size: 24px;
      font-family: 'open_sansbold';
      text-transform: uppercase;
      line-height: 18px;
      margin-bottom: 10px;

      span {
        color: #ffb010;
        display: inline;
        font-size: 24px;
        margin-bottom: 0;
      }
    }
  }

  .modal-footer {
    display: block;
    text-align: center;
    border: 0;

    button {
      width: 140px;
    }
  }
}

#submitProject {

  .close {
    position: absolute;
    background: url('../images/modal_close_button.jpg') no-repeat;
    opacity: 1;
    top: -16px;
    right: -16px;
    width: 35px;
    height: 35px;
  }

  .modal-dialog {
    min-width: 700px;

    .modal-content {
      text-align: right;
      border-radius: 0;
      padding-bottom: 54px;

      .modal-body {

        .modalInstructions {
          text-align: center;
          border-bottom: 1px solid #e3e3e3;
          margin-bottom: 31px;

          p {
            font-size: 14px;

            a {
              color: #ffac00;

              &:hover {
                text-decoration: none;
              }
            }
          }

          h3 {
            font-family: 'open_sansbold';
            text-transform: uppercase;
            padding: 0;

            span {
              color: #ffac00;
            }
          }
        }

        .uploadFileName  {
          position: relative;
          z-index: 2;
        }

        .list-unstyled .media  {
          position: relative;
        }

        .col-sm-12 {

          #drag-and-drop-zone {

            .btn-primary {
              background: #ffac00;
            }

            &.p-5 {
              padding: 1rem 3rem!important;
            }
          }

          #files {
              overflow-y: scroll !important;
              min-height: 185px;
          }

          .dm-uploader {
            border: 0.25rem dashed #A5A5C7;
            text-align: center;

            &.active {
              border-color: red;

              border-style: solid;
            }
          }
        }
      }

      .modalButtons {
        margin-right: 30px;

        button {
          background: #ffac00;
          min-width: 140px;
          margin-left: 20px;
        }
      }
    }
  }
}

.addtocalendar {
  display: inline-block;
  margin-left: 10px;
}
